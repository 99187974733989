class Game extends Layer
	particle_count : 256
	running: false
	particles: []
	particle_speed : 2
	started_once : false
	preload : () ->
		# @vert = DEFAULT_VERTEX_SHADER
		# @particle_frag = loadStrings "src/particle.frag"
	setup : () ->
		@last_time = 0
		@autopiloted = true
		# ts = 32
		# @particle_texture = createGraphics ts, ts
		# for x in [0..ts - 1]
		# 	t = x / ts
		# 	@particle_texture.fill t * 255
		# 	@particle_texture.rect x, 0, 1, ts
		# @particle_shader = @image.createShader @vert, @particle_frag

		# @particles =
		# 	for i in [1...@particle_count]
		# 		new Particle @layers.spawn.get_pos(), createVector( random(), random() ), 1.0
	show_menu : () ->
		@image.fill "#fffa"
		@image.translate 0,0,1
		@image.rect 0, 0, @size, @size
		@image.fill "#777f"
		w = 35
		h = 40

		@image.push()
		@image.translate @size * 0.5, @size * 0.5
		@image.beginShape()
		@image.vertex -w,-h
		@image.vertex w,0
		@image.vertex -w, h
		@image.endShape()
		@image.pop()

		# @image.beginPath()



	draw : (delta = 0, time = 0, external = false) ->
		@image.clear()

		@layers.topograph.focus()
		@layers.topograph.shader.setUniform "merged", 1.0
		@layers.topograph.draw delta, time, true
		@layers.topograph.defocus()


		# @layers.goal.draw delta, time, true
		@image.translate 0,0,1
		@layers.tile.draw delta, time, true
		@image.translate 0,0,0.5
		@layers.spawn.draw delta, time, true
		@image.translate 0,0,0.5

		if @running
			if time < @last_time
				@layers.tile.step_tiles()
			@last_time = time
			@image.strokeWeight @particles[0].diam
			@image.fill Colors.yellow
			@image.stroke Colors.yellow

			# @image.shader @particle_shader
			# @image.texture(@particle_texture)
			# @image.beginShape(POINTS)
			black = color(Colors.black)
			@image.noStroke()
			for p in @particles
				if p.living
					height = @layers.topograph.get_height(p.pos)
					dir = @layers.flow.get_direction(p.pos)
					p.update height, dir, delta

					in_cloud = @layers.clouds.collide_pos(p.pos)

					if not @pos_inside(p.pos) or ( @layers.tile.collide_pos(p.pos) and not in_cloud )
						p.living = false
					else if @layers.goal.collide_pos p.pos
						p.living = false
						# @layers.goal.add_score()

					@image.fill(p.color)
					@image.circle p.pos.x, p.pos.y, p.diam
				else
					if p.time < 1.0
						p.time += delta
						@image.fill(lerpColor(p.color, black,p.time))
						@image.circle p.pos.x, p.pos.y, p.diam * (1.0 - p.time)

					# @image.vertex p.pos.x, p.pos.y, 0, p.uv.x, p.uv.y

			# @image.endShape()
			# @image.resetShader()

		@image.strokeWeight 1
		@image.noFill()
		pulse_color = color(Colors.yellow)
		pulse_color.setAlpha((1 - time) * 255)
		@image.stroke pulse_color
		if @running
			for i in [0...@layers.goal.wave_num]
				t = i / (@layers.goal.wave_num - 1)
				@image.circle @layers.goal.pos.x, @layers.goal.pos.y, @layers.goal.margin * abs(sin( time * Math.PI * 0.5 )) * t

		@image.strokeWeight 1
		@image.noStroke()
		c = lerpColor color( Colors.yellow ), color( Colors.white ), 1 - time
		@image.fill c
		@image.circle @layers.goal.pos.x, @layers.goal.pos.y, @layers.goal.diam


		if @layers.goal.score > 0
			@image.fill 0, 0, 0, 128
			@image.stroke 0
			@image.textFont font
			@image.textSize @size * 0.05
			@image.textAlign CENTER, CENTER
			s = floor((@layers.goal.score / @particle_count) * 100)

			middle.html "#{s}%"

		if not @running 
			@show_menu()
			# @image.text "#{s}%", @layers.goal.pos.x, @layers.goal.pos.y - @image.textDescent()



	start : () ->
		@started_once = true
		@particles =
			for i in [1...@particle_count+1]
				new Particle @layers.spawn.get_pos(), createVector( random(), random() ), 0
		@layers.topograph.start()
		@layers.goal.start()
		# @layers.flow.start()
		@running = true

		middle.html "0%"
	stop : () ->
		for p in @particles
			p.living = false
		@running = false

	tap : (p) ->
		if not @running
			@start()
		else
			@stop()


	# blend : (delta) ->
		# @layers.flow.draw()
		# @draw(delta)
		# @draw_window @layers.topograph.image, @window
		# @draw_window @layers.flow.image, @window
		# @draw_window @layers.tile.image, @window
		# @draw_window @layers.spawn.image, @window
		# @draw_window @layers.goal.image, @window
		# @draw_window @image, @window
